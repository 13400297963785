
import { defineComponent, PropType } from 'vue'

import ListEmpty from '@/components/ListEmpty.vue'
import ListError from '@/components/ListError.vue'

import InfiniteLoading from '@/components/InfiniteLoading.vue'
import { InfiniteLoadingStateChanger } from '@/data/types'

export default defineComponent({
  components: { ListEmpty, ListError, InfiniteLoading },
  props: {
    records: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    error: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    errorMsg: {
      type: String as PropType<string>,
      default: 'Não foi possível carregar os dados.',
    },
    reloadCb: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
    infiniteCb: {
      type: Function as PropType<
        ($state: InfiniteLoadingStateChanger) => Promise<void>
      >,
      required: true,
    },
  },
})
