
import { MovementPopulated } from '@/data/movement/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    movement: {
      type: Object as PropType<MovementPopulated>,
      required: true,
    },
  },
  computed: {
    actionStyle(): string {
      return this.movement.action === 'IN' ? 'action--in' : 'action--out'
    },
  },
})
