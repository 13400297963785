
import { defineComponent, PropType } from 'vue'

import SelectFish from '@/components/selects/SelectFish.vue'
import SelectPond from '@/components/selects/SelectPond.vue'

import { MovementListFilters } from '@/data/movement/types'

export default defineComponent({
  components: { SelectFish, SelectPond },
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    filters: {
      type: Object as PropType<MovementListFilters>,
      default: null,
    },
  },
  emits: ['update:visible', 'apply'],
  data() {
    return {
      filtersLocal: {} as MovementListFilters,
    }
  },
  computed: {
    visibleComputed: {
      get(): boolean {
        return this.visible
      },
      set(visible: boolean) {
        this.$emit('update:visible', visible)
      },
    },
  },
  watch: {
    visibleComputed() {
      if (this.visibleComputed) {
        this.filtersLocal = { ...this.filters }
      }
    },
  },
  methods: {
    handleClear() {
      this.$emit('apply', {})
      this.closeModal()
    },
    handleApply() {
      this.$emit('apply', this.filtersLocal)
      this.closeModal()
    },
    closeModal() {
      this.visibleComputed = false
    },
  },
})
