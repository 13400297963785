
import { defineComponent, inject, PropType, ref } from 'vue'
import { PondRepositoryKey } from '@/data/injectables'
import { Pond } from '@/data/pond/types'
import { AutoCompleteEvent, VueComponent } from '@/data/types'

type PondOrQueryOrId = Pond | string

export default defineComponent({
  props: {
    modelValue: {
      type: [Object, String, Number] as PropType<PondOrQueryOrId>,
      default: '',
    },
    placeholder: {
      type: String as PropType<string>,
      default: 'Açude',
    },
    showInactive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const autoComplete = ref<VueComponent>()
    const pondRepository = inject(PondRepositoryKey)

    return {
      autoComplete,
      pondRepository,
    }
  },
  data() {
    return {
      ponds: [] as Pond[],
    }
  },
  computed: {
    modelValueComputed: {
      get(): PondOrQueryOrId {
        return this.modelValue
      },
      set(modelValue: PondOrQueryOrId) {
        this.$emit('update:modelValue', modelValue)
      },
    },
    loading() {
      return (
        typeof this.modelValueComputed === 'string' &&
        this.modelValueComputed === 'Carregando...'
      )
    },
  },
  created() {
    if (typeof this.modelValueComputed === 'number') {
      this.loadPond(this.modelValueComputed)
    }
  },
  methods: {
    focus() {
      const input = this.autoComplete?.$el.getElementsByTagName('input')[0]
      input?.focus()
    },
    async searchPond(event: AutoCompleteEvent) {
      try {
        const params = {
          name: event.query || undefined,
          inactive: this.showInactive ? undefined : false,
        }
        const ponds = await this.pondRepository?.getPonds(params)
        this.ponds = ponds || []
      } catch (e) {
        console.log(e)
        this.ponds = []
      }
    },
    async loadPond(pondId: number) {
      try {
        this.modelValueComputed = 'Carregando...'
        const pond = await this.pondRepository?.getPond(pondId)
        this.modelValueComputed = pond || ''
      } catch (e) {
        console.log(e)
        this.modelValueComputed = ''
      }
    },
  },
})
