
import { defineComponent, inject, PropType, ref } from 'vue'
import { FishRepositoryKey } from '@/data/injectables'
import { Fish } from '@/data/fish/types'
import { AutoCompleteEvent, VueComponent } from '@/data/types'

type FishOrQueryOrId = Fish | string | number

export default defineComponent({
  props: {
    modelValue: {
      type: [Object, String, Number] as PropType<FishOrQueryOrId>,
      default: '',
    },
    placeholder: {
      type: String as PropType<string>,
      default: 'Peixe',
    },
    showInactive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const autoComplete = ref<VueComponent>()
    const fishRepository = inject(FishRepositoryKey)

    return {
      autoComplete,
      fishRepository,
    }
  },
  data() {
    return {
      fishes: [] as Fish[],
    }
  },
  computed: {
    modelValueComputed: {
      get(): FishOrQueryOrId {
        return this.modelValue
      },
      set(modelValue: FishOrQueryOrId) {
        this.$emit('update:modelValue', modelValue)
      },
    },
    loading() {
      return (
        typeof this.modelValueComputed === 'string' &&
        this.modelValueComputed === 'Carregando...'
      )
    },
  },
  created() {
    if (typeof this.modelValueComputed === 'number') {
      this.loadFish(this.modelValueComputed)
    }
  },
  methods: {
    focus() {
      const input = this.autoComplete?.$el.getElementsByTagName('input')[0]
      input?.focus()
    },
    async searchFish(event: AutoCompleteEvent) {
      try {
        const params = {
          name: event.query,
          inactive: this.showInactive ? undefined : false,
        }
        const fishes = await this.fishRepository?.getFishes(params)
        this.fishes = fishes || []
      } catch (e) {
        console.log(e)
        this.fishes = []
      }
    },
    async loadFish(fishId: number) {
      try {
        this.modelValueComputed = 'Carregando...'
        const fish = await this.fishRepository?.getFish(fishId)
        this.modelValueComputed = fish || ''
      } catch (e) {
        console.log(e)
        this.modelValueComputed = ''
      }
    },
  },
})
